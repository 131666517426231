// extracted by mini-css-extract-plugin
export var globalWrapper = "Card-module--global-wrapper--OoUWv";
export var globalHeader = "Card-module--global-header--3kXil";
export var postListItem = "Card-module--post-list-item--3T0ZM";
export var bio = "Card-module--bio--2Y0uF";
export var bioAvatar = "Card-module--bio-avatar--QXXTC";
export var blogPost = "Card-module--blog-post--2Aw5_";
export var blogPostNav = "Card-module--blog-post-nav--2IK_Q";
export var gatsbyHighlight = "Card-module--gatsby-highlight--2RhiR";
export var screenReaderText = "Card-module--screen-reader-text--1QkK3";
export var container = "Card-module--container--2b-cd";
export var content = "Card-module--content--2nKGw";