// extracted by mini-css-extract-plugin
export var globalWrapper = "contact-module--global-wrapper--PkrYd";
export var globalHeader = "contact-module--global-header--UTgU0";
export var postListItem = "contact-module--post-list-item--3OUAg";
export var bio = "contact-module--bio--2jWzc";
export var bioAvatar = "contact-module--bio-avatar--1S9vV";
export var blogPost = "contact-module--blog-post--3Ske6";
export var blogPostNav = "contact-module--blog-post-nav--388tk";
export var gatsbyHighlight = "contact-module--gatsby-highlight--2hACs";
export var screenReaderText = "contact-module--screen-reader-text--3oFFL";
export var content = "contact-module--content--1H0a8";
export var contactDetails = "contact-module--contact-details--172cq";
export var contactAddress = "contact-module--contact-address--fKP3O";